
import EmployeeDetail from '@/components/employees/EmployeeDetail.vue';
import Vue from 'vue';

export default Vue.extend({
  props: ['employeeId'],
  components: { EmployeeDetail },
  metaInfo: {
    title: 'Employee - '
  },
  name: 'Employee',
  computed: {}
});
